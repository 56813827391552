body .message {
  background: #6190e8; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #a7bfe8,
    #6190e8
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #a7bfe8,
    #6190e8
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  padding: 50px 30px;
}

@media only screen and (min-width: 768px) {
  body .message {
    padding-top: 72px;
    padding-bottom: 89px;
  }
}

body .message .headline {
  font: normal 3.75rem/1 Poppins, sans-serif;
  color: #000909;
}

body .message .sub-headline {
  font-size: 20px;
  font-weight: 400;
  color: #000909;
}

body .message .subscribe {
  margin-top: 75px;
}

body .message .form-subscribe {
  padding: 12px 12px 12px 24px;
  background: #ffffff;
  border-radius: 12px;
  height: 64px;
  width: 459px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

body .message .input-text {
  font-size: 16px;
  font-weight: 600;
}

body .message .btn-subscribe {
  background: linear-gradient(276.78deg, #016a6a 0%, #02a6a6 100%);
  border-radius: 8px !important;
  font-size: 16px !important;
  font-weight: 600;
  color: #eeeeee;
}

@media only screen and (max-width: 768px) {
  body .message .btn-subscribe {
    font-size: 12px !important;
  }
}

body .cl-light {
  color: #eeeeee;
}

body .cl-light-grey-2 {
  color: #90bcb7;
}

body .font-lora {
  font-family: "Lora", sans-serif !important;
}

.button_slide {
  color: black;
  border: 2px solid #5969eb;
  border-radius: 0px;
  padding: 18px 36px;
  display: inline-block;
  font-family: "Lucida Console", Monaco, monospace;
  font-size: 14px;
  letter-spacing: 1px;
  cursor: pointer;
  width: 150px;
  margin-top: 20px;
  box-shadow: inset 0 0 0 0 #5969eb;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

.slide_down:hover {
  box-shadow: inset 0 100px 0 0 #5969eb;
}
