.footer-basic {
  padding: 40px 0;
  color: #f5efef;
  position: relative;
}

.footer-basic .social {
  text-align: center;
  padding-bottom: 25px;
}

.footer-basic .social > a {
  font-size: 24px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  /* border-radius: 50%; */
  /* border: 1px solid #ccc; */
  margin: 0 8px;
  color: inherit;
  opacity: 0.75;
}

.footer-basic .social > a:hover {
  opacity: 0.9;
}

.rarity-sniper {
  width: 200px !important;
  margin-bottom: 50px !important;
  display: block;
  margin: 0 auto;
}

.footer-basic .copyright {
  margin-top: 15px;
  text-align: center;
  font-size: 20px;
  color: #aaa;
  margin-bottom: 0;
}

.custom-shape-divider-top-1647850883 {
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1647850883 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 25px;
}

.custom-shape-divider-top-1647850883 .shape-fill {
  fill: #27214e;
}
