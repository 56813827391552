.masthead {
  position: relative;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/background.png");
}

.masthead h1 {
  font-size: 4rem;
}

.navbar {
  border-bottom: 1px solid white;
  background-color: #322970;
}

.nav-link {
  color: white !important;
  text-shadow: 0.5px 0.5px #0c0c0c;
  font-size: 1rem !important;
  font-weight: bold;
}

.navbar-nav a:hover {
  text-shadow: 2px 2px #110808;
  color: #ec8222 !important;
  border-bottom: 2px solid #ec8222;
}

.mint-btn {
  display: none;
}

/* countdown */

.launch-time {
  display: flex;
  margin: 0 auto;
}

.launch-time div {
  padding: 5px;
}

.launch-time div p {
  text-align: center;
  margin: 0px !important;
  font-size: 60px;
  margin-bottom: -14px;
}

.countdown-card {
  background-color: #ff007f;
  min-width: 50px;
  border-radius: 3px;
}

/* svg */
.custom-shape-divider-bottom-1647825201 {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1647825201 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 53px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1647825201 .shape-fill {
  fill: #27214e;
}

/* responsive */

@media only screen and (min-width: 1000px) {
  .countdown-card {
    width: 100px;
  }
}

@media only screen and (max-width: 1200px) {
  .masthead h1 {
    font-size: 2.5rem;
  }
}

@media only screen and (max-width: 1000px) {
  .custom-shape-divider-bottom-1647825201 svg {
    height: 20px;
  }
}
