@import url(https://fonts.googleapis.com/css2?family=Balsamiq+Sans:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&display=swap);
@font-face {
  font-family:consolas;
  src: url(/static/media/Chapeau-Light.990d7084.woff);
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "consolas", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
  background-color: #141432;
}

p {
  text-align: justify;
}

h1 {
  font-weight: bold;
}

.masthead {
  position: relative;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/static/media/background.8bd93f75.png);
}

.masthead h1 {
  font-size: 4rem;
}

.navbar {
  border-bottom: 1px solid white;
  background-color: #322970;
}

.nav-link {
  color: white !important;
  text-shadow: 0.5px 0.5px #0c0c0c;
  font-size: 1rem !important;
  font-weight: bold;
}

.navbar-nav a:hover {
  text-shadow: 2px 2px #110808;
  color: #ec8222 !important;
  border-bottom: 2px solid #ec8222;
}

.mint-btn {
  display: none;
}

/* countdown */

.launch-time {
  display: flex;
  margin: 0 auto;
}

.launch-time div {
  padding: 5px;
}

.launch-time div p {
  text-align: center;
  margin: 0px !important;
  font-size: 60px;
  margin-bottom: -14px;
}

.countdown-card {
  background-color: #ff007f;
  min-width: 50px;
  border-radius: 3px;
}

/* svg */
.custom-shape-divider-bottom-1647825201 {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1647825201 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 53px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1647825201 .shape-fill {
  fill: #27214e;
}

/* responsive */

@media only screen and (min-width: 1000px) {
  .countdown-card {
    width: 100px;
  }
}

@media only screen and (max-width: 1200px) {
  .masthead h1 {
    font-size: 2.5rem;
  }
}

@media only screen and (max-width: 1000px) {
  .custom-shape-divider-bottom-1647825201 svg {
    height: 20px;
  }
}

/* Customize your main colors in :root variables */
:root {
  --main-background-color: #343a50;
  --card-background-color: #51576d;
  --card-background-lighter-color: #7a7f92;
  --main-text-color: #f7f6f4;
  --title-text-color: #3cba8b;
}

h2,
p {
  color: #f7f6f4;
  color: var(--main-text-color);
}

h3 {
  font-weight: normal;
}

.MuiButton-contained {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQQAAAA9CAYAAABLChfbAAABWElEQVR4nO3dPW6DQBRGUSZy6yJNNuDGm8l2swX2QkPjOkRR5E/5wZnY1kgoc05JgQziXTEC4fL68rwMQK/KaRxz7A8uA+BMEIAQBCAEAYidUwH9KtO87A/HHL87BCAEAYjqkqFM849ta5anx5WtdX/dP/xXrWfnmv27QwBCEIAQBCAEAQhBAEIQgBAEIAQBiOqLSbe+NHHmxSP43aUZqc3evbO5xh0CEIIAhCAAIQhACAIQggCEIAAhCEAIAhCCAIQgACEIQAgCEIIAhCAAIQhACAIQggCEIAAhCEAIAhCCAET1M+yXPhH9XYtPQkMPbp2dFrNZDcK9aj/G/zbAdlgyACEIQAgCEIIAhCAAIQhANH/sWOP9BdiOahAMLGxTi9m0ZABCEIAQBCAEAQhBAEIQgHh/7Fg+byjTvDg90KfdaRy/HPj+cHQpQKcsGYAQBCAEAfgwDMMbjQYssua6z8gAAAAASUVORK5CYII=) !important ;
}

.about {
  position: relative;
  background-color: #27214e;
}

.about .content-8-3 .frame {
  position: relative;
}

.about .content-8-3 .frame::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  box-sizing: border-box;
  border: 2px solid #fff;
  transform: translate(-30px, 22px);
  transition: transform 250ms ease;
}

.about .content-8-3 .frame:hover::before {
  width: 100%;
  height: 100%;
  z-index: 2;
  transform: translate(0px, 0px);
}

.about .content-8-3 .btn-read {
  color: #ffffff;
  font-weight: 700;
  padding: 1.25rem 1.25rem 1.25rem 0rem;
  transition: 0.2s;
}

.about .content-8-3 .btn-read:hover {
  color: #d75a00;
  transition: 0.2s;
}

.about .content-8-3 .btn-read:hover svg path {
  fill: #d75a00;
  transition: 0.2s;
}

.about .content-8-3 .text-gray-1 {
  color: white;
}

.about .content-8-3 .main {
  padding: 2.5rem 2rem 2rem;
}

.about .content-8-3 .left-column,
.about .content-8-3 .right-column {
  width: 100%;
}

.about .content-8-3 .right-column {
  margin-top: 6rem;
}

.about .content-8-3 .caption-top {
  font: normal 1.25rem/1.75rem Poppins, sans-serif;
  margin-bottom: 1rem;
}

.about .content-8-3 .title-font {
  font: normal 3.75rem/1 Poppins, sans-serif;
  margin-bottom: 1rem;
}

.about .content-8-3 .caption-text {
  font-weight: 500;
  margin-bottom: 1rem;
}

/* responsive */

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .about .content-8-3 .main {
    padding: 2.5rem 4rem 2rem;
  }
}

@media (min-width: 992px) {
  .about .content-8-3 .main {
    padding: 6rem 6rem 8rem;
  }

  .about .content-8-3 .left-column,
  .about .content-8-3 .right-column {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .about .content-8-3 .main {
    padding: 6rem 9rem 8rem;
  }
}

@media (max-width: 1024px) {
  .about .content-8-3 .frame::before {
    width: 100%;
    height: 100%;
    transform: translate(-10px, 10px);
    transition: transform 250ms ease;
  }
}

/* svg */

.custom-shape-divider-bottom-1647827281 {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1647827281 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 53px;
}

.custom-shape-divider-bottom-1647827281 .shape-fill {
  fill: #322970;
}

.footer-basic {
  padding: 40px 0;
  color: #f5efef;
  position: relative;
}

.footer-basic .social {
  text-align: center;
  padding-bottom: 25px;
}

.footer-basic .social > a {
  font-size: 24px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  /* border-radius: 50%; */
  /* border: 1px solid #ccc; */
  margin: 0 8px;
  color: inherit;
  opacity: 0.75;
}

.footer-basic .social > a:hover {
  opacity: 0.9;
}

.rarity-sniper {
  width: 200px !important;
  margin-bottom: 50px !important;
  display: block;
  margin: 0 auto;
}

.footer-basic .copyright {
  margin-top: 15px;
  text-align: center;
  font-size: 20px;
  color: #aaa;
  margin-bottom: 0;
}

.custom-shape-divider-top-1647850883 {
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1647850883 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 25px;
}

.custom-shape-divider-top-1647850883 .shape-fill {
  fill: #27214e;
}

.vid {
  scroll-margin-top: 4rem;
  background-color: #322970;
}

video {
  max-width: 100%;
  height: auto;
}

@media only screen and (max-width: 1279) {
  .vid {
    height: 300px !important;
  }
}

body .message {
  background: #6190e8; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #a7bfe8,
    #6190e8
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  padding: 50px 30px;
}

@media only screen and (min-width: 768px) {
  body .message {
    padding-top: 72px;
    padding-bottom: 89px;
  }
}

body .message .headline {
  font: normal 3.75rem/1 Poppins, sans-serif;
  color: #000909;
}

body .message .sub-headline {
  font-size: 20px;
  font-weight: 400;
  color: #000909;
}

body .message .subscribe {
  margin-top: 75px;
}

body .message .form-subscribe {
  padding: 12px 12px 12px 24px;
  background: #ffffff;
  border-radius: 12px;
  height: 64px;
  width: 459px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

body .message .input-text {
  font-size: 16px;
  font-weight: 600;
}

body .message .btn-subscribe {
  background: linear-gradient(276.78deg, #016a6a 0%, #02a6a6 100%);
  border-radius: 8px !important;
  font-size: 16px !important;
  font-weight: 600;
  color: #eeeeee;
}

@media only screen and (max-width: 768px) {
  body .message .btn-subscribe {
    font-size: 12px !important;
  }
}

body .cl-light {
  color: #eeeeee;
}

body .cl-light-grey-2 {
  color: #90bcb7;
}

body .font-lora {
  font-family: "Lora", sans-serif !important;
}

.button_slide {
  color: black;
  border: 2px solid #5969eb;
  border-radius: 0px;
  padding: 18px 36px;
  display: inline-block;
  font-family: "Lucida Console", Monaco, monospace;
  font-size: 14px;
  letter-spacing: 1px;
  cursor: pointer;
  width: 150px;
  margin-top: 20px;
  box-shadow: inset 0 0 0 0 #5969eb;
  transition: ease-out 0.4s;
}

.slide_down:hover {
  box-shadow: inset 0 100px 0 0 #5969eb;
}

.faq {
  background-color: #27214e;
  scroll-margin-top: 4rem;
}

.faq .panel {
  border-bottom: 1px solid rgb(250, 247, 247);
  padding: 5px;
}

.faq .panel-default > .panel-heading {
  border: 0;
  padding: 0;
  border-radius: 10px;
}

.faq .panel-default .panel-title a {
  display: block;
  font-size: 1.5rem;
  font-weight: bold;
  color: white !important;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}

.faq .panel-default .panel-title a:after {
  font-family: "FontAwesome";
  font-style: normal;
  font-size: 2rem;
  content: "\f106";
  color: white;
  float: right;
  margin-top: -12px;
}

.faq .panel-default .panel-title a.collapsed:after {
  content: "\f107";
}

.faq .panel-default .panel-body {
  font-size: 1.2rem;
  /* font-family: "ABeeZee", sans-serif; */
}

.faq .title-font {
  font: normal 3.75rem/1 Poppins, sans-serif;
  margin-bottom: 1rem;
  color: #f6037f;
}

@media only screen and (max-width: 1000px) {
  .faq h1 {
    font-size: 1.7rem !important;
    text-shadow: 1px 1px #f7c154;
  }
}

/* PRELOADER CSS */
.page-loader {
  width: 100%;
  height: 100vh;
  position: absolute;
  background: #272727;
  z-index: 1000;
}
.txt {
  color: #666;
  text-align: center;
  top: 40%;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-weight: bold;
  line-height: 1.5;
}
/* SPINNER ANIMATION */
.spinner {
  position: relative;
  top: 35%;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  background-color: #fff;

  border-radius: 100%;
  animation: sk-scaleout 1s infinite ease-in-out;
}

@keyframes sk-scaleout {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

