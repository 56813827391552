@import url("https://fonts.googleapis.com/css2?family=Balsamiq+Sans:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");

@font-face {
  font-family:consolas;
  src: url("./assets/Chapeau-Light.woff");
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "consolas", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
  background-color: #141432;
}

p {
  text-align: justify;
}

h1 {
  font-weight: bold;
}
